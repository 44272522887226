:root {
  --primary-color: #214874;
  --secondary-color: #004876;
  --color-text: #575756;
  --color-verde: #006a68;
  --color-gris: #919191;
  --color-rojo: #811730;
  --titulos: semplicitapro, sans-serif;
  --regular: 400;
  --style: normal;
  --grande: 30px;
  --tipografia-2: Avenir, sans-serif;
}

body {
  margin: 0;
  color: var(--color-text);
  font-family: var(--tipografia-2);
  padding: 2rem;
}

main,
header {
  /*width: 1850px;*/
  margin: 2rem auto;
  max-width: calc(100% - 4rem);
}

h1,
h2,
h3 {
  color: var(--primary-color);
  font-family: var(--titulos);
}

button {
  color: #fff;
  font-family: var(--tipografia-2);
  margin: 0.5rem 1rem 0.5rem 0;
  background-color: var(--primary-color);
  border: none;
  padding: 0.5rem;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1)
}

button:hover {
  background-color: var(--color-verde);
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1)
}

button.realizarCotizacion {
  width: 100%;
}

select {
  padding: .5rem;
  margin-bottom: 0.5rem;
  border: 1px solid var(--primary-color);
  border-radius: 3px;
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1)
}

select:focus,
select:focus-within {
  border: 1px solid var(--color-verde);
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1)
}


/***************************************************/

.App {
  /*max-width: 1800px;*/
  margin: auto;
}

header.App-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

img.App-logo-proyecto {
  margin-left: auto;
  margin-right: 4rem;
}

button.acceder {
  /*height: 50px; */
  border-radius: 4px;
  /* width: 50px; */
  padding: 0;
  border: 3px solid var(--primary-color);
  background-color: transparent;
  color: var(--primary-color);
  font-size: 12px;
  padding: 0.5rem;
}

.acceder {
  text-align: center;
}

.login {
  text-align: right;
  display: flex;
  align-items: center;
  position: relative;
}

.login img {
  width: 50px;
  border-radius: 50px;
}

.user_data {
  padding: 2rem 0;
}

.user_data .float {
  visibility: hidden;
  position: absolute;
  top: calc(60px + 2rem);
  right: 0;
  border: 1px solid;
  padding: 1rem;
  border-radius: 10px;
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  background-color: white;
}

.user_data:hover .float {
  visibility: visible;
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.user_data .float h4,
.user_data .float p {
  margin: 0;
}

.float button {
  width: 100%;
  background-color: #919191;
  color: #575756;
  transition: none;
}


/***************************************************/
table {
  border-collapse: separate;
  border-spacing: 0px;
  display: block;
  overflow-x: auto;
  width: -moz-fit-content;
  width: fit-content;
  max-width: calc(100%);
}

table.noBorder {
  border: none;
  padding: 0;
}

section.flexDirection.flexEnd table {
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  padding: 0.5rem;
}

.inventario table.noBorder tr:nth-child(odd) {
  background-color: transparent;
}

.inventario thead {
  position: -webkit-sticky;
  /* for Safari */
  position: sticky;
  top: 0;
  background-color: #fff;
  box-shadow: 0px 1px 0 var(--primary-color);
}

.inventario thead tr,
thead tr th {
  font-size: 12px;
  text-align: right;
}

table th,
table td {
  white-space: nowrap;
}

.inventario thead tr th {
  text-transform: uppercase;
  padding: 7px 10px;
  border-right: 1px solid rgb(197, 197, 197);
  text-align: center;
}

.inventario tbody tr:nth-child(odd) {
  background-color: rgb(197, 197, 197);
}

.inventario tbody tr td {
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  padding: 5px 3px;
}

table.customInputs td {
  font-size: 12px;
}

table.cotizarInmueble {
  margin-top: 1rem;
}


#cotizacionImprimir table {
  border: 1px solid #575756;
}

.container_tablas {
  position: relative;
  width: 90vw;
  height: 90vh;
  overflow: scroll;
  border: 1px solid var(--primary-color);
  border-radius: 4px;
}

.container_tablas table {

  display: table !important;
}

table.TERRENOS.SAN.PATRICIO.GARDENS.lista_inventario th,
table.TERRENOS.SAN.PATRICIO.GARDENS.lista_inventario td {
  min-width: 90px;
}

/***************************************************/


.flexDirection.flexEnd {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.columnDirection {
  display: flex;
  flex-direction: column;
  width: 48%;
}

.inputsCotizador table,
.inputsCotizador table tr {
  width: 100%;
}

.inputsCotizador table tr td:first-child {
  text-align: start;
  max-width: 140px;
}

.inputsCotizador table tr td {
  width: 10%;
  font-size: 14px;
  text-align: end;
}

.inputsCotizador table tr td:nth-child(3) {
  font-style: italic;
}

.inputsCotizador table tr td:last-child,
.inputsCotizador table thead tr th:last-child {
  text-align: right;
  font-weight: 600;
}

.inputsCotizador table thead tr th:last-child {
  text-transform: uppercase;
}

tr.disponibilidad td:last-child {
  padding: 0.5rem 0;
  text-align: center !important;
  font-size: 13px;
}


input.editado {
  background-color: var(--color-rojo);
  color: #fff;
}

.green {
  background-color: var(--color-verde);
  color: #fff;
}

.gray {
  background-color: var(--color-gris);
  color: #fff;
}

.rojo {
  background-color: var(--color-rojo);
  color: #fff;
}

tr.disponibilidad .green,
tr.disponibilidad .gray,
tr.disponibilidad .rojo {
  border-radius: 4px;
}


.selectorInmuebles {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/**************************************************************/

aside.cerrar_detalle {
  position: fixed;
  top: 0;
  right: -100%;
  width: 300px;
  height: 100%;
  max-width: 80vw;
  background-color: white;
  border: 1px solid gray;
  display: none;
}

aside.mostrar_detalle {
  position: fixed;
  top: 0;
  right: 0%;
  height: 100%;
  width: 100%;
  display: block;
}

aside.mostrar_detalle:before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #80808040;
  z-index: -1;
  backdrop-filter: blur(3px);
}

aside.mostrar_detalle header {
  margin-left: 0;
  margin-right: 0;
}

aside.mostrar_detalle>div {
  max-width: 80vw;
  width: 300px;
  border: 1px solid gray;
  padding: 3rem;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 6rem);
  float: right;
  position: relative;
  z-index: 2;
  overflow-y: auto;
}

aside.mostrar_detalle p.gray {
  color: var(--color-gris);
  background-color: transparent;
  font-weight: bold;
}

aside.mostrar_detalle p.green {
  color: var(--color-verde);
  background-color: transparent;
  font-weight: bold;
}

aside.mostrar_detalle p.rojo {
  color: var(--color-rojo);
  background-color: transparent;
  font-weight: bold;
}

aside.mostrar_detalle a {
  color: #fff;
  font-family: var(--tipografia-2);
  margin: 0.5rem 1rem 0.5rem 0;
  background-color: var(--primary-color);
  border: none;
  padding: 0.5rem;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  text-decoration: none;
  text-align: center;
  font-size: 12px;
}

aside.mostrar_detalle a:hover {
  background-color: var(--color-verde);
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

aside.mostrar_detalle a:last-child {
  margin-right: 0;
}

aside .cerrar {
  color: white;
  font-family: var(--tipografia-2);
  margin: 0.5rem 1rem 0.5rem 0;
  background-color: var(--color-gris);
  border: none;
  padding: 0.2rem;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  position: absolute;
  top: 2rem;
  right: 2rem;
  height: 30px;
  width: 30px;
}

aside.mostrar_detalle footer {
  display: flex;
  justify-content: space-between;
}

div#hubspotForm .hs-input {
  width: calc(100% - 0.64em - 2px);
  padding: 0.32em;
  min-height: calc(32px - 0.64em);
  margin-bottom: 0.5rem;
  border: 1px solid var(--primary-color);
  border-radius: 3px;
}

div#hubspotForm select.hs-input {
  min-height: 34px !important;
  width: 100%;
}

.hs-form-field label.hs-error-msg {
  font-size: 12px;
  color: red;
}

ul.no-list.hs-error-msgs.inputs-list {
  padding: 0;
  margin: 0 0 1rem 0;
  list-style: none;
}

input.hs-button.primary.large {
  color: #fff;
  font-family: var(--tipografia-2);
  margin: 0.5rem 1rem 0.5rem 0;
  background-color: var(--color-verde);
  border: none;
  padding: 0.5rem;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  text-decoration: none;
  text-align: center;
  font-size: 12px;
}

.hs-form-field label {
  font-size: 14px;
  margin-bottom: 3px;
  display: block;
}


div#hubspotForm .hs_siglas_proyecto {
  display: none !important;
}

div#hubspotForm .hs-proyecto {
  display: none !important;
}

div#hubspotForm .hs_fecha_de_cotizacion {
  display: none !important;
}

/********************************************/

#cotizacionImprimir {
  display: none;
}

.detalleCotizacion {
  max-width: 80vw;
  margin: auto;
}

header.cotizacion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  max-width: 80vw;
  margin: auto;
}

.datosAsesor {
  text-align: right;
  font-size: 12px;
  width: 100%;
  margin-top: 1rem;
}

.datosAsesor p {
  margin-top: 0;
  margin-bottom: 0.5em;
}

.detalleCotizacion tr td,
.inventario .detalleCotizacion table tbody tr th {
  width: auto;
  padding: 0.5rem 1rem !important;
}

.detalleCotizacion tbody tr td {
  padding-bottom: 0.5rem !important;
  text-align: end;
  width: 25%;
  max-width: 25%;
}


.detalleCotizacion tbody tr td:first-child {
  text-align: start;
}

#cotizacionImprimir {
  border-bottom: 1px solid var(--primary-color);
  height: 100%;
  width: calc(100% - 4rem);
  margin: auto;
  max-width: 80vw;
}

#cotizacionImprimir .disclaimer {
  margin-top: 2rem;
}

#cotizacionImprimir .disclaimer p {
  font-size: 12px;
}

#cotizacionImprimir table {
  padding-bottom: 0;
  width: 100%;
  display: table;
}

#cotizacionImprimir table thead {
  box-shadow: none !important;
}

#cotizacionImprimir table thead th {
  border-bottom: 1px solid var(--primary-color) !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.25rem !important;
  font-weight: bold !important;
  color: #575756 !important;
  text-align: right;
  width: 18%;
  padding-right: 10px;
}

#cotizacionImprimir table.dato-cliente thead th,
#cotizacionImprimir table.dato-cliente tbody td {
  text-align: center;
}

#cotizacionImprimir table.dato-inmueble thead th {
  text-align: right;
}

#cotizacionImprimir h3 {
  color: #575756 !important;
}

#cotizacionImprimir table thead th:last-child {
  border-right: none;
}

.inventario #cotizacionImprimir tbody tr:nth-child(odd) {
  background-color: transparent;
}

.resalt {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 16px !important;
  margin-top: 15px;
  border-top: 1px solid;
}



.cont-notificaciones .notificacion {
  height: 30px;
  padding: 5px;
  margin: 5px;
  border-radius: 5px;
  position: relative;
}

.cont-notificaciones .notificacion .Close {
  position: absolute;
  top: 3px;
  right: 3px;
  cursor: pointer;
}

.cont-notificaciones .notificacion p {
  margin: 0;
  font-size: 15px;
  font-weight: bold;
}

.cont-notificaciones .notificacion.red {
  color: white;
  background-image: linear-gradient(to bottom, #FF2828, #FF7373);
}

.cont-notificaciones .notificacion.yellow {
  color: white;
  background-image: linear-gradient(to bottom, #DCDC00, #ECEC00);
}

.cont-notificaciones .notificacion.green {
  color: white;
  background-image: linear-gradient(to bottom, #50E600, #7CFF36);
}




@page {
  size: A4;
  margin: 0;
}

@media print {
  #cotizacionImprimir {
    display: block !important;
    width: 180mm;
    height: 267mm;
    margin: auto;
  }

  h2.title_proyectos,
  .btn_proyectos,
  table.lista_inventario,
  aside.mostrar_detalle,
  table.noBorder,
  header.App-header,
  .btn_router,
  section.flexDirection.flexEnd,
  .cotizador h2,
  .btn_cotizador {
    display: none !important;
  }

  p.bodyText {
    font-family: georgia, sans-serif;
  }

  body {
    padding: 0;
  }

  @media (max-width: 476px) {
    body {
      padding: 0rem;
    }
  }

}

/************************************************************************************************/
@media (max-width: 476px) {
  body {
    padding: 1rem;
    width: calc(100vw - 2rem);
  }

  main,
  header {
    max-width: 100%;
    margin: 1rem 0 0 0;
  }

  header.App-header {
    margin: 0 auto;
  }

  header.App-header img.App-logo {
    max-width: 80px;
  }

  .user_data {
    padding: 0;
  }

  .login img {
    width: 40px;
  }

  table {
    padding: 5px;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  h2.title_proyectos,
  .btn_cotizador h2 {
    text-align: center;
    max-width: calc(100vw - 2rem);
    margin-left: auto;
    margin-right: auto;
  }

  .btn_cotizador h2 {
    width: 100%;
  }

  .btn_proyectos,
  .btn_cotizador {
    display: flex;
    flex-wrap: wrap;
  }

  .btn_proyectos button,
  .btn_cotizador button {
    font-size: 12px;
    margin: 0.2rem 0.4rem;
  }

  table.noBorder {
    width: 100%;
  }

  section.flexDirection.flexEnd {
    flex-wrap: wrap;
  }

  .inputsCotizador.columnDirection {
    width: 100%;
  }

  table.customInputs tr {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  table.customInputs tr td,
  .inputsCotizador table tr td:first-child,
  .inputsCotizador table tr td:nth-child(3) {
    width: 50%;
    padding: 0;
  }

  table.customInputs tr td input {
    width: 100%;
  }

  table.cotizarInmueble {
    position: sticky;
    bottom: 0;
    background-color: white;
  }

  .inputsCotizador table tr td {
    font-size: 12px;
  }

  .inventario thead tr th {
    padding: 5px;
  }

  .user_data .float {
    top: calc(20px + 2rem);
  }
}














/***************************************************************************************************/

/* Estos son datos que debemos quitar de la aplicación */

#precio_por_m2_interno,
#precio_del_patio_azotea,
#precio_bodegas,
#precio_del_balcon,
#cuota_al_banco_0,
#cuota_al_banco,
#precio_de_balcon,
#abono_a_gastos_legales,
#no,
#parcela,
#uso_de_suelo,
#name,
#parqueo {
  display: none;
}




/****************************************************************************************************/